import { graphql, StaticQuery, useStaticQuery } from "gatsby";
import React, { ReactElement, ReactNode, useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import parse from "html-react-parser";
import { SplashContainer } from "../components/SplashContainer";
import { Button } from "luxd";
import { FaDiscord, FaExternalLinkAlt } from "react-icons/fa";
import { joinDiscord } from "../utils/navigation";

type FaqCardProps = {
    title: ReactNode;
    content: ReactNode;
};

const OpenSvg = (
    <svg
        fill="currentColor"
        className="question-chevron bg-primary h-5 block text-offWhite rounded-full p-1"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            stroke="currentColor"
            stroke-width="1"
            fill="currentColor"
            fill-rule="evenodd"
        >
            <g>
                <polygon points="10.7071068 7.05025253 10 6.34314575 4.34314575 12 5.75735931 13.4142136 10 9.17157288 14.2426407 13.4142136 15.6568542 12"></polygon>
            </g>
        </g>
    </svg>
);

const ClosedSvg = (
    <svg
        fill="currentColor"
        className="question-chevron bg-primary h-5 block text-offWhite rounded-full p-1"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            stroke="currentColor"
            stroke-width="1"
            fill="currentColor"
            fill-rule="evenodd"
        >
            <g>
                <polygon points="9.29289322 12.9497475 10 13.6568542 15.6568542 8 14.2426407 6.58578644 10 10.8284271 5.75735931 6.58578644 4.34314575 8"></polygon>
            </g>
        </g>
    </svg>
);

const FaqCard = (props: FaqCardProps) => {
    const { title, content } = props;
    const [open, setOpen] = useState(false);

    return (
        <div className="question-and-answer mx-4 md:mx-8 my-3 rounded-lg text-sm  flex flex-col space-y-4">
            <dt
                className="bg-tertiary p-4 rounded-md"
                onClick={() => setOpen(!open)}
            >
                <div className="flex justify-between">
                    <h1 className="text-offWhite font-body text-xl">{title}</h1>
                </div>
            </dt>
            <dd className="text-offWhite text-base py-4 px-8 bg-dark rounded-md">
                {content}
            </dd>
        </div>
    );
};

type FaqItem = {
    question: string;
    answerHtml: string;
};

const FAQ = (): ReactElement => {
    const data = useStaticQuery(graphql`
        {
            allContentfulFaqItem {
                nodes {
                    answer {
                        childrenContentfulRichText {
                            html
                        }
                    }
                    question
                }
            }
        }
    `);

    const faqItems: FaqItem[] = data.allContentfulFaqItem.nodes?.map(item => ({
        question: item.question,
        answerHtml: item.answer.childrenContentfulRichText[0].html,
    }));

    return (
        <Layout>
            <SEO title="FAQ" description="Streamlux App FAQ" />
            <SplashContainer className="flex-grow md:py-16 flex flex-col space-y-8 py-2 px-4 md:px-0">
                <h1 className="text-center sm:text-xl md:text-2xl lg:text-5xl font-display text-offWhite">
                    Frequently Asked Questions
                </h1>
                <div>
                    <div className="mt-8">
                        <div>
                            <dl className="mx-auto max-w-screen-sm lg:max-w-screen-md grid sm:grid-cols-1 lg:grid-cols-1 bg-darkGray rounded-md md:p-8 p-0 py-8 space-y-4">
                                <div className="text-offWhite text-xl justify-center flex flex-col items-center md:flex-row space-x-2">
                                    <p>Find more information at the</p>

                                    <a
                                        className="text-site-secondary hover:underline flex flex-row items-center space-x-1"
                                        href="https://help.streamlux.com"
                                        target="_blank"
                                    >
                                        <div>Streamlux Help Center</div>
                                        <div>
                                            <FaExternalLinkAlt className="text-base" />
                                        </div>
                                    </a>

                                    <div></div>
                                </div>
                                {faqItems.map(item => {
                                    return (
                                        <div className="">
                                            <FaqCard
                                                title={item.question}
                                                content={parse(item.answerHtml)}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="flex flex-col items-center">
                                    <Button
                                        icon={<FaDiscord />}
                                        onClick={() => joinDiscord()}
                                    >
                                        Join our Discord
                                    </Button>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </SplashContainer>
        </Layout>
    );
};

export default FAQ;
